import { 
    CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    GET_USER_CAMPAIGNS,
    GET_ALL_CAMPAIGNS,
    GET_CAMPAIGN_ANALYSIS,
    GET_CAMPAIGN_GIVERS,
    LIST_CAMPAIGNS,
    CAMPAIGN_RESET,
    DASHBOARD_CAMPAIGNS,
    REFERRAL_USER_CAMPAIGNS
 } from '../constants';
export const campaignReducer = (state, action) => {
    switch (action.type) {
        case CREATE_CAMPAIGN:
            return {
                ...state,
                addCampaign: action.payload
            };
        case UPDATE_CAMPAIGN:
            return {
                ...state,
                updateCampaign: action.payload
            };
        case GET_USER_CAMPAIGNS:
            return {
                ...state,
                getUserCampaign: action.payload,
            };
        case REFERRAL_USER_CAMPAIGNS:
            return {
                ...state,
                getReferralUserCampaigns: action.payload,
            };
        case GET_ALL_CAMPAIGNS:
            return {
                ...state,
                getAllCampaigns: action.payload,
            };
        case LIST_CAMPAIGNS:
            return {
                ...state,
                listCampaigns: action.payload,
            };
        case GET_CAMPAIGN_ANALYSIS:
            return {
                ...state,
                getCampaignAnalysis: action.payload,
            };
        case GET_CAMPAIGN_GIVERS:
            return {
                ...state,
                getCampaignGivers: action.payload,
            };
        case CAMPAIGN_RESET:
            return {
                ...state,
                ...action.payload
            };
        case DASHBOARD_CAMPAIGNS:
            return {
                ...state,
                dashboardCampaign: action.payload
            };
        default:
        return state;
    }
};