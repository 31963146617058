import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { reducer } from './redux/reducer';
import * as initialState from './redux/initialization';


import { StateProvider } from './common/jss/react-jss';

import Spinner from "./common/Spinner";
import "./App.css";
import PublicAuthRoute from './components/Routes/PublicAuthRoute';

const Login = lazy(() => import("./pages/Login/Login"));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const SetTarget = lazy(() => import('./pages/SetTarget/SetTarget'));
const Traffic = lazy(() => import('./pages/Traffic/Traffic'));
const Campaign = lazy(() => import('./pages/Campaign/Campaign'));
const Resources = lazy(() => import('./pages/Resources/Resources'));
const Referral = lazy(() => import('./pages/Referral/Referral'));
const MakeReferral = lazy(() => import('./pages/MakeReferral/MakeReferral'))
const AssociateReferral = lazy(() => import('./pages/AssociateReferral/AssociateReferral'))
const UserCampaigns = lazy(() => import('./pages/UserCampaigns/UserCampaigns'))
const ImpactTimeline = lazy(() => import('./pages/ImpactTimeline/ImpactTimeline'));
const TrafficList = lazy(() => import('./pages/TrafficLists/TrafficLists'));
const SingleCampaign = lazy(() => import('./pages/SingleCampaign/SingleCampaign'));

function App() {
  return (
    <StateProvider initialState={{ ...initialState }} reducer={reducer}>
      <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <>
          <Route exact path="/" component={Login}  />
          <PublicAuthRoute exact path="/dashboard" component={Dashboard}  />
          <PublicAuthRoute exact path="/set-target" component={SetTarget}  />
          <PublicAuthRoute exact path="/traffic/view-all" component={TrafficList}  />
          <PublicAuthRoute exact path="/traffic" component={Traffic}  />
          <PublicAuthRoute exact path="/campaign" component={Campaign}  />
          <PublicAuthRoute exact path="/resources" component={Resources}  />
          <PublicAuthRoute exact path="/referral" component={Referral}  />
          <PublicAuthRoute exact path="/make-referral" component={MakeReferral}  />
          <PublicAuthRoute exact path="/referral/:id/assocuate-users" component={AssociateReferral}  />
          <PublicAuthRoute exact path="/user/:id/campaigns" component={UserCampaigns}  />
          <PublicAuthRoute exact path="/impact-timeline" component={ImpactTimeline}  />
          <PublicAuthRoute exact path="/campaigns/dashboard" component={SingleCampaign}  />
          </>
        </Switch>
        </Suspense>
      </BrowserRouter>
    </StateProvider>
  
  );
}

export default App;
