import { REGISTER_USER, LOGIN_USER, LOGOUT_USER, AUTH_RESET } from '../constants';
export const authReducer = (state, action) => {
    switch (action.type) {
        case REGISTER_USER:
            return {
                ...state,
                register: action.payload
            };
        case LOGIN_USER:
            return {
                ...state,
                login: action.payload
            };
        case LOGOUT_USER:
            return {
                ...state,
                login: null,
                register: null,
            };
        case AUTH_RESET:
            return {
                ...state,
                ...action.payload
            };
        default:
        return state;
    }
};