import React, { useEffect, useRef, useState} from 'react';
import {useStyle} from './jss';

const styles = theme => ({
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 6,
        "& .path": {
            strokeDasharray: 170,
            strokeDashoffset: 20,
        },
        "& .spinner": `
            animation: rotate 1.4s linear infinite;
            -webkit-animation: rotate 1.4s linear infinite;
            -moz-animation: rotate 1.4s linear infinite;
            width:174px;
            height:174px;
            position: relative;
        `,
        "& .spinner-dot": `
            width:274px;
            height:274px;
            position: relative;
            top: 0;
        `
    },
})

const Spinner = (props) => {
    const colorChangeTimer = useRef();
    const [colorChanger, setColorChanger] = useState(true);
    const { classes, theme} = useStyle(styles);

    useEffect(() => {
        colorChangeTimer.current = setInterval(() => {
            setColorChanger(prev => !prev);
        }, 1400);
        document.body.style.overflowX = "hidden";
        return () => {
            clearInterval(colorChangeTimer.current);
            document.body.style.overflowX = "auto";
        }
    }, []);

    return (
        <div className={classes.wrapper}>
            <svg className="spinner" width="174px" height="174px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="transparent" strokeWidth="2" cx="33" cy="33" r="30" stroke={`url(#${colorChanger ? "gradientOrange" : "gradientBlue"})`} />
                <linearGradient id="gradientOrange">
                    <stop offset="50%" stopColor={theme.primary} stopOpacity="1"/>
                    <stop offset="65%" stopColor={theme.primary} stopOpacity=".5"/>
                    <stop offset="100%" stopColor={theme.primary} stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="gradientBlue">
                    <stop offset="50%" stopColor={theme.secondary} stopOpacity="1"/>
                    <stop offset="65%" stopColor={theme.secondary} stopOpacity=".5"/>
                    <stop offset="100%" stopColor={theme.secondary} stopOpacity="0"/>
                </linearGradient>
                <svg className="spinner-dot dot" width="5px" height="5px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" x="37" y="1.5">
                    <circle className="path" fill={ colorChanger ? theme.primary : theme.secondary} cx="33" cy="33" r="30"/>
                </svg> 
            </svg> 
        </div>
    )
}

export default Spinner;