export const theme = {
  primary: '#0065d5',
  secondary: '#e7e801',
  tertiary: '#fbfbfd',
  grey: '#595959',
  gold: '#bab924',
  color: "#ffffff",
  zoom: 0.8,
  fontScale: 0.8,
  scale: 1.75,
  alertSuccess: {
    color: '#1e1e35',
    bgColor: '#74c671',
  },
  alertError: {
    color: '#ffcfcf',
    bgColor: '#d43333',
  },
  alertInfo: {
    color: '#1e1e35',
    bgColor: '#03A9F4',
  },
  ff: 'Arial, Helvetica, sans-serif',
  flexible: () => ({
    display: 'flex',
  }),
  breakpoints: {
    xxsDown: '@media only screen and (max-width: 360px)',
    xxsUp: '@media only screen and (min-width: 360px)',
    xxsXs: '@media only screen and (min-width: 361px) and (max-width:479px)',
    xsDown: '@media only screen and (max-width: 480px)',
    xsUp: '@media only screen and (min-width: 480px)',
    xsSm: '@media only screen and (min-width: 481px) and (max-width: 599px)',
    smDown: '@media only screen and (max-width: 600px)',
    smUp: '@media only screen and (min-width: 600px)',
    smMd: '@media only screen and (min-width: 601px) and (max-width: 767px)',
    mdDown: '@media only screen and (max-width: 768px)',
    mdUp: '@media only screen and (min-width: 768px)',
    mdLg: '@media only screen and (min-width: 769px) and (max-width: 991px)',
    lgDown: '@media only screen and (max-width: 992px)',
    lgUp: '@media only screen and (min-width: 992px)',
    lgXl: '@media only screen and (min-width: 993px) and (max-width: 1199px)',
    mdXl: '@media only screen and (min-width: 769px) and (max-width: 1199px)',
    xlDown: '@media only screen and (max-width: 1200px)',
    xlUp: '@media only screen and (min-width: 1200px)',
    xxlDown: '@media only screen and (max-width: 1400px)',
    xxlUp: '@media only screen and (min-width: 1400px)',
  },
};
