import { 
    GET_ALL_USERS,
    ADD_REFERRAL,
    REMOVE_REFERRAL,
    GET_ALL_REFERRALS,
    GET_ALL_NON_REFERRALS,
    REFERRAL_RESET,
    REFERRAl_ASSOCIATE_USERS
 } from '../constants';
export const referralReducer = (state, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                getAllUsers: action.payload
            };
        case REFERRAl_ASSOCIATE_USERS:
            return {
                ...state,
                getReferralAssociateUsers: action.payload
            };
        case GET_ALL_REFERRALS:
            return {
                ...state,
                getAllReferredUsers: action.payload
            };
        case GET_ALL_NON_REFERRALS:
            return {
                ...state,
                getAllNonReferredUsers: action.payload
            };
        case ADD_REFERRAL:
            return {
                ...state,
                addReferral: action.payload
            };
        case REMOVE_REFERRAL:
            return {
                ...state,
                removeReferral: action.payload,
            };
        case REFERRAL_RESET:
                return {
                    ...state,
                    ...action.payload
                };
        default:
        return state;
    }
};