import React from 'react';
import {Route, Redirect} from "react-router-dom";
import PropTypes from 'prop-types';
import { removeLocalStorage } from '../../helper/storage';

const RouterControl = (props) => {
    const { component: Component, accessible, ...rest } = props;
    

    return (
        <Route exact={props.path === '/'} {...rest} render={(props) => {
          
            if(!accessible()){
              removeLocalStorage('auth');
                return  (
                    <Redirect to={{ pathname: '/', state: { from: props.location }}} /> 
                 )  
            }
            
            return  (
                <Component {...props} {...rest} />)
        }}
        />
)
};

RouterControl.propTypes = {
  accessible: PropTypes.func.isRequired,
};

export default RouterControl;
