import React from 'react';
import RouterControl from './RouterControl';
import { isEmpty } from "../../helper/util";
import { getLocalStorage } from "../../helper/storage";

const PublicAuthRoute = props => {

  return (
    <RouterControl
      accessible={() => !isEmpty(getLocalStorage("auth", "data", "token"))}
      {...props}
    />
  );
};

export default PublicAuthRoute;
